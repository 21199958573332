import * as React from 'react';
import {AppBar, Toolbar, Typography, Link, Box, Container, Tabs, Tab, Button, IconButton, Grid, Menu, MenuItem } from '@mui/material';
import { ReactComponent as Logo } from './/Assets/logo.svg';
import { ReactComponent as Map } from './/Assets/img/icons/map.svg';
import { ReactComponent as Phone } from './/Assets/img/icons/phone.svg';
import { ReactComponent as Mail } from './/Assets/img/icons/mail.svg';
import BG from './/Assets/img/bg.jpg';
import { ReactComponent as Motor } from './/Assets/img/motor.svg';
import { ReactComponent as Certificate } from './/Assets/img/sertificate.svg';
import { ReactComponent as Guarantee } from './/Assets/img/garantiya.svg';
import { ReactComponent as Delivery } from './/Assets/img/Delivery.svg';
import Catalog1 from './/Assets/img/Catalogs/1.png';
import Catalog2 from './/Assets/img/Catalogs/2.png';
import Catalog3 from './/Assets/img/Catalogs/3.png';
import Catalog4 from './/Assets/img/Catalogs/4.png';
import Catalog5 from './/Assets/img/Catalogs/5.png';
import { ReactComponent as Tab1 } from './/Assets/img/icons/Tab1.svg';
import { ReactComponent as Tab2 } from './/Assets/img/icons/Tab2.svg';
import { ReactComponent as Tab3 } from './/Assets/img/icons/Tab3.svg';
import { ReactComponent as Delivery1 } from './/Assets/img/Delivery/Delivery1.svg';
import { ReactComponent as Delivery2 } from './/Assets/img/Delivery/Delivery2.svg';
import { ReactComponent as Delivery3 } from './/Assets/img/Delivery/Delivery3.svg';
import { ReactComponent as Contact1 } from './/Assets/img/icons/с_map.svg';
import { ReactComponent as Contact2 } from './/Assets/img/icons/с_mail.svg';
import { ReactComponent as Contact3 } from './/Assets/img/icons/с_phone.svg';
import { ReactComponent as Contact4 } from './/Assets/img/icons/с_time.svg';
import { ReactComponent as Copyright } from './/Assets/img/icons/copyright.svg';
import Map_ufa from './/Assets/img/map_ufa.png';

import MontserratRegular from './/Assets/fonts/Montserrat-Regular.ttf';

import { ReactComponent as MenuIcon } from './/Assets/img/icons/Menu.svg';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{
                    p: { xs: 3, md: 6, },
                    bgcolor: 'white'
                }}>
                    {children}
                </Box>
            )}
        </div>
    );
}
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

  return(
      <div className="app_page">
          <AppBar
              position="fixed"
              color="inherit"
              sx={{
                  pt: 2,
                  pb: 2,
                  flexGrow: 1,
                  display: { md: 'none',},
                  boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.25)',
              }}>
              <Toolbar sx={{
                  minHeight: { xs: 'auto', },
              }}>
                  <Box sx={{ flexGrow: 1, }}>
                      <Link href="/" ><Logo /></Link>
                      <Typography sx={{
                          fontFamily: 'Montserrat',
                          fontWeight: '500',
                          fontSize: '10px',
                          lineHeight: '14px',
                      }}>
                          Поставщик насосного оборудования в России
                      </Typography>
                  </Box>

                  <IconButton
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                  >
                      <MenuIcon />
                  </IconButton>
                  <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                          'aria-labelledby': 'basic-button',
                      }}
                  >
                      <Link
                          href="https://docs.google.com/spreadsheets/d/1rPJDQbJkYtbmWBJM5-Nhuq2oEghrdxBSNW83VZ8J4Ho/edit?usp=sharing"
                          target="_blank"
                          underline="none"
                          color="inherit"
                      >
                          <MenuItem>Наличие</MenuItem>
                      </Link>
                      <Link
                          href="https://docs.google.com/spreadsheets/d/1U-4zCpaVnMQcRfgNsvoTnU7LArqr8jOmeMN73ErXIcM/edit?usp=sharing"
                          target="_blank"
                          underline="none"
                          color="inherit"
                      >
                          <MenuItem>Прайс-лист</MenuItem>
                      </Link>
                      <Link
                          href="#Delivery"
                          underline="none"
                          color="inherit"
                      >
                          <MenuItem>Доставка</MenuItem>
                      </Link>
                      <Link
                          href="#Requisites"
                          underline="none"
                          color="inherit"
                      >
                          <MenuItem>Реквизиты</MenuItem>
                      </Link>
                      <Link
                          href="#Contacts"
                          underline="none"
                          color="inherit"
                      >
                          <MenuItem>Контакты</MenuItem>
                      </Link>
                  </Menu>

              </Toolbar>
          </AppBar>
          <AppBar
              position="static"
              color="inherit"
              sx={{
                  color: '#3B3B3B',
                  display: { xs: 'none', md: 'block', },
              }}>
              <Container
                  maxWidth
                  sx={{
                  display: 'flex',
                  gap: 3,
                  alignItems: 'center',
                  p: { md: 3, lg: '24px 120px', },
              }}>
                  <Link
                      href="/"
                      sx={{
                          alignSelf: 'center',
                          flexGrow: { md: 1, lg: 0, },
                      }}
                  >
                      <Logo />
                  </Link>
                  <Typography
                      sx={{
                          fontFamily: 'Montserrat',
                          fontWeight: '500',
                          fontSize: '13px',
                          lineHeight: '21px',
                          flexGrow: 1,
                          display: { md: 'none', lg: 'block', },
                  }}>
                      Поставщик насосного<br /> оборудования в России
                  </Typography>
                  <Box sx={{
                      display: 'flex',
                      gap: 1,
                      alignItems: 'center',
                      alignSelf: 'center',
                  }}>
                      <Map />
                      <Typography sx={{
                          fontFamily: 'Montserrat',
                          fontWeight: '500',
                          fontSize: '12px',
                          lineHeight: '14px',
                      }} >
                          г. Уфа, ул. Уфимское шоссе 36, офис 20
                      </Typography>
                  </Box>
                  <Link href="tel:+7(937)3263295"
                        underline="none"
                        color="inherit"
                        sx={{
                            display: 'flex',
                            gap: 1,
                            alignItems: 'center',
                            alignSelf: 'center',
                        }}>
                      <Phone />
                      <Typography sx={{
                          fontFamily: 'Montserrat',
                          fontWeight: '500',
                          fontSize: '12px',
                          lineHeight: '14px',
                      }} >
                          +7 (937) 326-32-95
                      </Typography>
                  </Link>
                  <Link
                      href="mailto:office@tkars-service.ru"
                      underline="none"
                      color="inherit"
                      sx={{
                          display: 'flex',
                          gap: 1,
                          alignItems: 'center',
                          alignSelf: 'center',
                      }}>
                      <Mail />
                      <Typography sx={{
                          fontFamily: 'Montserrat',
                          fontWeight: '500',
                          fontSize: '12px',
                          lineHeight: '14px',
                      }} >
                          office@tkars-service.ru
                      </Typography>
                  </Link>
              </Container>
              <Box
                   sx={{
                       bgcolor: '#EDEEF5',
                       height: '60px',
                       display: 'flex',
                       p: { lg: '0 120px', },
                   }}>
                  <Link
                      href="https://docs.google.com/spreadsheets/d/1rPJDQbJkYtbmWBJM5-Nhuq2oEghrdxBSNW83VZ8J4Ho/edit?usp=sharing"
                      target="_blank"
                      underline="none"
                      color="inherit"
                      sx={{
                          p: '23px 20px',
                          fontFamily: 'Montserrat',
                          fontWeight: '700',
                          fontSize: '14px',
                          lineHeight: '14px',
                          '&:hover': {
                              border: 0,
                              color: 'white',
                              backgroundColor: '#209581',
                          },
                      }}>
                      Наличие
                  </Link>
                  <Link
                      href="https://docs.google.com/spreadsheets/d/1U-4zCpaVnMQcRfgNsvoTnU7LArqr8jOmeMN73ErXIcM/edit?usp=sharing"
                      target="_blank"
                      underline="none"
                      color="inherit"
                      sx={{
                          p: '23px 20px',
                          fontFamily: 'Montserrat',
                          fontWeight: '700',
                          fontSize: '14px',
                          lineHeight: '14px',
                          '&:hover': {
                              border: 0,
                              color: 'white',
                              backgroundColor: '#209581',
                          },
                      }}>
                      Прайс-лист
                  </Link>
                  <Link
                      href="#Delivery"
                      underline="none"
                      color="inherit"
                      sx={{
                          p: '23px 20px',
                          fontFamily: 'Montserrat',
                          fontWeight: '700',
                          fontSize: '14px',
                          lineHeight: '14px',
                          '&:hover': {
                              border: 0,
                              color: 'white',
                              backgroundColor: '#209581',
                          },
                      }}>
                      Доставка
                  </Link>
                  <Link
                      href="#Requisites"
                      underline="none"
                      color="inherit"
                      sx={{
                          p: '23px 20px',
                          fontFamily: 'Montserrat',
                          fontWeight: '700',
                          fontSize: '14px',
                          lineHeight: '14px',
                          '&:hover': {
                              border: 0,
                              color: 'white',
                              backgroundColor: '#209581',
                          },
                      }}>
                      Реквизиты
                  </Link>
                  <Link
                      href="#Contacts"
                      underline="none"
                      color="inherit"
                      sx={{
                          p: '23px 20px',
                          fontFamily: 'Montserrat',
                          fontWeight: '700',
                          fontSize: '14px',
                          lineHeight: '14px',
                          '&:hover': {
                              border: 0,
                              color: 'white',
                              backgroundColor: '#209581',
                          },
                      }}>
                      Контакты
                  </Link>
              </Box>
          </AppBar>
          <Box sx={{
              display: 'flex',
              minHeight: { xs: '410px', md: '550px', },
              background: `bottom no-repeat url(${BG})`,
              backgroundSize: 'cover',
          }}>
          </Box>

          <Grid
              container
              spacing={{ xs: 2, sm: 3 }}
              sx={{
                  display: 'flex',
                  //flexWrap: 'wrap',
                  bgcolor: '#EDEEF5',
                  width: '100% !important',
                  height: { lg: '118px' },
                  m: { xs: 0, },
                  p: { xs: 2, md: 3, lg: '0 120px', },
                  //gap: 3,
                  //gap: { xs: 2, sm: 3 },
              }}
          >
              <Grid
                  xs={6}
                  sm={3}
                  sx={{
                      width: '100%',
                      display: 'flex',
                      gap: 2,
                      p: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: {xs: 'column', lg: 'row', },
                  }}
              >
                  <Motor />
                  <Typography
                          sx={{
                              fontFamily: 'Montserrat',
                              fontWeight: '700',
                              fontSize: { xs: '14px', md: '15px', },
                              lineHeight: { xs: '14px', md: '20px', },
                              alignSelf: 'center',
                              textAlign: { xs: 'center', lg: 'left' },
                          }} >
                          Большой выбор оборудования
                  </Typography>
              </Grid>
              <Grid
                  xs={6}
                  sm={3}
                  sx={{
                      width: '100%',
                      display: 'flex',
                      gap: 2,
                      p: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: {xs: 'column', lg: 'row', },
                  }}
              >
                  <Certificate />
                  <Typography
                      sx={{
                          fontFamily: 'Montserrat',
                          fontWeight: '700',
                          fontSize: { xs: '14px', md: '15px', },
                          lineHeight: { xs: '14px', md: '20px', },
                          alignSelf: 'center',
                          textAlign: { xs: 'center', lg: 'left' },
                      }} >
                      Весь товар сертифицирован
                  </Typography>
              </Grid>
              <Grid
                  xs={6}
                  sm={3}
                  sx={{
                      width: '100%',
                      display: 'flex',
                      gap: 2,
                      p: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: {xs: 'column', lg: 'row', },
                  }}
              >
                  <Guarantee />
                  <Typography
                      sx={{
                          fontFamily: 'Montserrat',
                          fontWeight: '700',
                          fontSize: { xs: '14px', md: '15px', },
                          lineHeight: { xs: '14px', md: '20px', },
                          alignSelf: 'center',
                          textAlign: { xs: 'center', lg: 'left' },
                      }} >
                      Гарантия на товар
                  </Typography>
              </Grid>
              <Grid
                  xs={6}
                  sm={3}
                  sx={{
                      width: '100%',
                      display: 'flex',
                      gap: 2,
                      p: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: {xs: 'column', lg: 'row', },
                  }}
              >
                  <Delivery />
                  <Typography
                      sx={{
                          fontFamily: 'Montserrat',
                          fontWeight: '700',
                          fontSize: { xs: '14px', md: '15px', },
                          lineHeight: { xs: '14px', md: '20px', },
                          alignSelf: 'center',
                          textAlign: { xs: 'center', lg: 'left' },
                      }} >
                      Удобная и быстрая доставка
                  </Typography>
              </Grid>
          </Grid>

          <Container maxWidth sx={{
              p: { xs: 2, sm: 3, lg: '48px 120px', },
          }}>
              <Typography
                  sx={{
                      fontFamily: 'Montserrat',
                      fontWeight: '700',
                      fontSize: { xs: '20px', md: '36px', },
                      lineHeight: { xs: '24px', md: '44px', },
                      alignSelf: 'stretch',
                      mb: { xs: 2, sm: 3, lg: 6, },
                  }} >
                  Каталог товаров
              </Typography>

              <Box sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
              }}>
                  <Button
                      href="https://docs.google.com/spreadsheets/d/1U-4zCpaVnMQcRfgNsvoTnU7LArqr8jOmeMN73ErXIcM/edit?usp=sharing"
                      target="_blank"
                      underline="none"
                      color="inherit"
                      sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          width: { xs: '164px', md: '300px', },
                          height: { xs: '164px', md: '300px', },
                          p: { xs: 1, md: '24px 32px', },
                          gap: { md: 2, },
                          textTransform: 'none',
                          border: '1px solid #EDEEF5',
                          borderRadius: 0,
                          '&:hover': {
                              border: '1px solid white',
                              background: 'white',
                              boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.25)',
                          },
                      }}>
                      <img width='100%' src={Catalog1} />
                      <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          height: '100%',
                      }}>
                          <Typography
                              sx={{
                                  fontFamily: 'Montserrat',
                                  fontWeight: '500',
                                  fontSize: { xs: '12px', md: '16px', },
                                  lineHeight: { xs: '14px', md: '20px', },
                                  textAlign: 'center',
                              }} >
                              Насос Wilo TOP-S
                          </Typography>
                          <Typography
                              sx={{
                                  fontFamily: 'Montserrat',
                                  fontWeight: '500',
                                  fontSize: { xs: '12px', md: '16px', },
                                  lineHeight: { xs: '14px', md: '20px', },
                                  color: '#C2332B',
                              }} >
                              от 39 000 руб./шт.
                          </Typography>
                      </Box>
                  </Button>
                  <Button
                      href="https://docs.google.com/spreadsheets/d/1U-4zCpaVnMQcRfgNsvoTnU7LArqr8jOmeMN73ErXIcM/edit?usp=sharing"
                      target="_blank"
                      underline="none"
                      color="inherit"
                      sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          width: { xs: '164px', md: '300px', },
                          height: { xs: '164px', md: '300px', },
                          p: { xs: 1, md: '24px 32px', },
                          gap: { md: 2, },
                          textTransform: 'none',
                          border: '1px solid #EDEEF5',
                          borderRadius: 0,
                          '&:hover': {
                              border: '1px solid white',
                              background: 'white',
                              boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.25)',
                          },
                      }}>
                      <img width='100%' src={Catalog2} />
                      <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          height: '100%',
                      }}>
                          <Typography
                              sx={{
                                  fontFamily: 'Montserrat',
                                  fontWeight: '500',
                                  fontSize: { xs: '12px', md: '16px', },
                                  lineHeight: { xs: '14px', md: '20px', },
                                  textAlign: 'center',
                              }} >
                              Циркуляционный насос с сухим ротором Wilo IPL
                          </Typography>
                          <Typography
                              sx={{
                                  fontFamily: 'Montserrat',
                                  fontWeight: '500',
                                  fontSize: { xs: '12px', md: '16px', },
                                  lineHeight: { xs: '14px', md: '20px', },
                                  color: '#C2332B',
                              }} >
                              от 45 0000 руб./шт.
                          </Typography>
                      </Box>
                  </Button>
                  <Button
                      href="https://docs.google.com/spreadsheets/d/1U-4zCpaVnMQcRfgNsvoTnU7LArqr8jOmeMN73ErXIcM/edit?usp=sharing"
                      target="_blank"
                      underline="none"
                      color="inherit"
                      sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          width: { xs: '164px', md: '300px', },
                          height: { xs: '164px', md: '300px', },
                          p: { xs: 1, md: '24px 32px', },
                          gap: { md: 2, },
                          textTransform: 'none',
                          border: '1px solid #EDEEF5',
                          borderRadius: 0,
                          '&:hover': {
                              border: '1px solid white',
                              background: 'white',
                              boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.25)',
                          },
                      }}>
                      <img width='100%' src={Catalog3} />
                      <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          height: '100%',
                      }}>
                          <Typography
                              sx={{
                                  fontFamily: 'Montserrat',
                                  fontWeight: '500',
                                  fontSize: { xs: '12px', md: '16px', },
                                  lineHeight: { xs: '14px', md: '20px', },
                                  textAlign: 'center',
                              }} >
                              Напорная установка отвода сточной воды Wilo HiSewlift
                          </Typography>
                          <Typography
                              sx={{
                                  fontFamily: 'Montserrat',
                                  fontWeight: '500',
                                  fontSize: { xs: '12px', md: '16px', },
                                  lineHeight: { xs: '14px', md: '20px', },
                                  color: '#C2332B',
                              }} >
                              от 30 000 руб./шт.
                          </Typography>
                      </Box>
                  </Button>
                  <Button
                      href="https://docs.google.com/spreadsheets/d/1U-4zCpaVnMQcRfgNsvoTnU7LArqr8jOmeMN73ErXIcM/edit?usp=sharing"
                      target="_blank"
                      underline="none"
                      color="inherit"
                      sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          width: { xs: '164px', md: '300px', },
                          height: { xs: '164px', md: '300px', },
                          p: { xs: 1, md: '24px 32px', },
                          gap: { md: 2, },
                          textTransform: 'none',
                          border: '1px solid #EDEEF5',
                          borderRadius: 0,
                          '&:hover': {
                              border: '1px solid white',
                              background: 'white',
                              boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.25)',
                          },
                      }}>
                      <img width='100%' src={Catalog4} />
                      <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          height: '100%',
                      }}>
                          <Typography
                              sx={{
                                  fontFamily: 'Montserrat',
                                  fontWeight: '500',
                                  fontSize: { xs: '12px', md: '16px', },
                                  lineHeight: { xs: '14px', md: '20px', },
                                  textAlign: 'center',
                              }} >
                              Wilo-Stratos PICO
                          </Typography>
                          <Typography
                              sx={{
                                  fontFamily: 'Montserrat',
                                  fontWeight: '500',
                                  fontSize: { xs: '12px', md: '16px', },
                                  lineHeight: { xs: '14px', md: '20px', },
                                  color: '#C2332B',
                              }} >
                              от 30 000 руб./шт.
                          </Typography>
                      </Box>
                  </Button>
                  <Button
                      href="https://docs.google.com/spreadsheets/d/1U-4zCpaVnMQcRfgNsvoTnU7LArqr8jOmeMN73ErXIcM/edit?usp=sharing"
                      target="_blank"
                      underline="none"
                      color="inherit"
                      sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          width: { xs: '164px', md: '300px', },
                          height: { xs: '164px', md: '300px', },
                          p: { xs: 1, md: '24px 32px', },
                          gap: { md: 2, },
                          textTransform: 'none',
                          border: '1px solid #EDEEF5',
                          borderRadius: 0,
                          '&:hover': {
                              border: '1px solid white',
                              background: 'white',
                              boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.25)',
                          },
                      }}>
                      <img width='100%' src={Catalog5} />
                      <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          height: '100%',
                      }}>
                          <Typography
                              sx={{
                                  fontFamily: 'Montserrat',
                                  fontWeight: '500',
                                  fontSize: { xs: '12px', md: '16px', },
                                  lineHeight: { xs: '14px', md: '20px', },
                                  textAlign: 'center',
                              }} >
                              Wilo Star-RS с гайками
                          </Typography>
                          <Typography
                              sx={{
                                  fontFamily: 'Montserrat',
                                  fontWeight: '500',
                                  fontSize: { xs: '12px', md: '16px', },
                                  lineHeight: { xs: '14px', md: '20px', },
                                  color: '#C2332B',
                              }} >
                              от 13 000 руб./шт.
                          </Typography>
                      </Box>
                  </Button>
              </Box>
          </Container>

          <Container maxWidth sx={{
              p: { xs: 2, sm: 3, lg: '48px 120px', },
              bgcolor: '#EDEEF5',
          }}>
              <Typography
                  id="Delivery"
                  sx={{
                      fontFamily: 'Montserrat',
                      fontWeight: '700',
                      fontSize: { xs: '20px', md: '36px', },
                      lineHeight: { xs: '24px', md: '44px', },
                      alignSelf: 'stretch',
                      mb: { xs: 2, sm: 3, lg: 6, },
                  }} >
                  Доставка
              </Typography>

              <Box sx={{ width: '100%', }}>
                  <Box>
                      <Tabs
                          value={value}
                          onChange={handleChange}
                          variant="fullWidth"
                          textColor="#3B3B3B"
                          bgcolor="white"
                          aria-label="icon label tabs example"
                          TabIndicatorProps={{hidden: true,}}
                          sx={{
                              "& button.Mui-selected": {
                                  borderTop: '3px solid #209581',
                                  bgcolor: 'white',},
                          }}
                      >
                          <Tab sx={{
                              fontFamily: 'Montserrat',
                              fontStyle: 'normal',
                              fontWeight: '700',
                              fontSize: { xs: '12px', md: '16px', },
                              lineHeight: { xs: '14px', md: '16px', },
                              textTransform: 'none',
                              p: { xs: 1, md: '0px 32px 32px', },
                              justifyContent: 'flex-end',
                              height: { xs: '100px', md: '163px', },
                            }}
                              icon={<Tab1 height={{ xs: '80px', md: '115px', }} />}
                              label="Доставка по городу"
                              {...a11yProps(0)}
                          />
                          <Tab sx={{
                              fontFamily: 'Montserrat',
                              fontStyle: 'normal',
                              fontWeight: '700',
                              fontSize: { xs: '12px', md: '16px', },
                              lineHeight: { xs: '14px', md: '16px', },
                              textTransform: 'none',
                              p: { xs: 1, md: '0px 32px 32px', },
                              justifyContent: 'flex-end',
                              height: { xs: '100px', md: '163px', },
                            }}
                              icon={<Tab2 height={{ xs: '80px', md: '115px', }} />}
                              label="Доставка по РФ"
                              {...a11yProps(1)}
                          />
                          <Tab sx={{
                              fontFamily: 'Montserrat',
                              fontStyle: 'normal',
                              fontWeight: '700',
                              fontSize: { xs: '12px', md: '16px', },
                              lineHeight: { xs: '14px', md: '16px', },
                              textTransform: 'none',
                              p: { xs: 1, md: '0px 32px 32px', },
                              justifyContent: 'flex-end',
                              height: { xs: '100px', md: '163px', },
                            }}
                               icon={<Tab3 height={{ xs: '40px', md: '115px', }} />}
                               label="Самовывоз"
                               {...a11yProps(2)}
                          />
                      </Tabs>
                  </Box>
                  <TabPanel value={value} index={0}>
                      <Typography sx={{
                          fontFamily: 'Montserrat',
                          fontStyle: 'normal',
                          fontWeight: '700',
                          fontSize: { xs: '16px', md: '24px', },
                          lineHeight: { xs: '16px', md: '29px', },
                          mb: 3,
                      }}>
                          Условия доставки по Уфе
                      </Typography>
                      <Typography sx={{
                          fontFamily: 'Montserrat',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                          mb: 3,
                      }}>
                          Доставляем заказы по Уфе и пригороду в будние и выходные дни, зона курьерской доставки отмечена красным цветом.<br />
                          Если вы живете за ее пределами, доставка возможна только по согласованию с менеджером.
                      </Typography>
                      <img width='100%' src={Map_ufa} />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                      <Typography sx={{
                          fontFamily: 'Montserrat',
                          fontStyle: 'normal',
                          fontWeight: '700',
                          fontSize: { xs: '16px', md: '24px', },
                          lineHeight: { xs: '16px', md: '29px', },
                          mb: 3,
                      }}>
                          Условия оформления заказов
                      </Typography>
                      <Typography sx={{
                          fontFamily: 'Montserrat',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                          mb: 3,
                      }}>
                          Доставка заказа транспортной компанией производится за счет покупателя.
                          Доставка до пункта отправки транспортной компании осуществляется бесплатно.
                          Доставка осуществляется в города РФ, в которых имеется пункты выдачи:
                      </Typography>
                      <Typography sx={{
                          fontFamily: 'Montserrat',
                          fontStyle: 'normal',
                          fontWeight: '700',
                          fontSize: { xs: '16px', md: '24px', },
                          lineHeight: { xs: '16px', md: '29px', },
                          mb: 3,
                      }}>
                          Доставим ваш заказ с помощью транспортной компании
                      </Typography>
                      <Typography sx={{
                          fontFamily: 'Montserrat',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                          mb: 3,
                      }}>
                          Чтобы рассчитать примерную стоимость, перейдите по ссылке.
                      </Typography>
                      <Box sx={{
                          height: { lg: '100px', },
                          display: 'flex',
                          flexWrap: 'wrap',
                      }}>
                          <Link href="https://tk-kit.ru/order" target="_blank"><Delivery1 width="100%" /></Link>
                          <Link href="https://pecom.ru/services-are/shipping-request/" target="_blank"><Delivery2 width="100%" /></Link>
                          <Link href="https://www.dellin.ru/requests/" target="_blank"><Delivery3 width="100%" /></Link>
                      </Box>
                      <Typography sx={{
                          fontFamily: 'Montserrat',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                          mb: 3,
                      }}>
                          *Срок доставки транспортной компанией “КИТ” осуществляется в более короткий срок за счет наличия сборного паллетного борта, что ускоряет срок обрешетки груза.
                      </Typography>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                      <Typography sx={{
                          fontFamily: 'Montserrat',
                          fontStyle: 'normal',
                          fontWeight: '700',
                          fontSize: { xs: '16px', md: '24px', },
                          lineHeight: { xs: '16px', md: '29px', },
                          mb: 3,
                      }}>
                          Самовывоз
                      </Typography>
                      <Typography sx={{
                          fontFamily: 'Montserrat',
                          fontStyle: 'normal',
                          fontWeight: '400',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          Забрать заказ можно самостоятельно в нашем офисе по адресу:<br />
                          г. Уфа, ул. Уфимское шоссе 36, офис 20, пн.-сб.: с 9.00 до 19.00; вс.: с 9.00 до 18.00
                      </Typography>
                  </TabPanel>
              </Box>
          </Container>


          <Container maxWidth sx={{
              p: { xs: 2, sm: 3, lg: '48px 120px', },
          }}>
              <Typography
                  id="Requisites"
                  sx={{
                      fontFamily: 'Montserrat',
                      fontWeight: '700',
                      fontSize: { xs: '20px', md: '36px', },
                      lineHeight: { xs: '24px', md: '44px', },
                      alignSelf: 'stretch',
                      mb: { xs: 2, sm: 3, lg: 6, },
                  }} >
                  Реквизиты
              </Typography>

              <Box>
                  <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      borderBottom: '1px solid #EDEEF5',
                      p: '8px 0px',
                      gap: 1,
                  }}>
                      <Box sx={{
                          width: '100%',
                          fontFamily: 'Montserrat',
                          fontWeight: '500',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          Полное наименование
                      </Box>
                      <Box sx={{
                          width: '100%',
                          fontFamily: 'Montserrat',
                          fontWeight: '400',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          Общество с ограниченной ответственностью «ТК АРС-СЕРВИС»
                      </Box>
                  </Box>
                  <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      borderBottom: '1px solid #EDEEF5',
                      p: '8px 0px',
                      gap: 1,
                  }}>
                      <Box sx={{
                          width: '100%',
                          fontFamily: 'Montserrat',
                          fontWeight: '500',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          Сокращенное наименование
                      </Box>
                      <Box sx={{
                          width: '100%',
                          fontFamily: 'Montserrat',
                          fontWeight: '400',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          ООО «ТК АРС-СЕРВИС»
                      </Box>
                  </Box>
                  <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      borderBottom: '1px solid #EDEEF5',
                      p: '8px 0px',
                      gap: 1,
                  }}>
                      <Box sx={{
                          width: '100%',
                          fontFamily: 'Montserrat',
                          fontWeight: '500',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          Юридический адрес
                      </Box>
                      <Box sx={{
                          width: '100%',
                          fontFamily: 'Montserrat',
                          fontWeight: '400',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          450502, Республика Башкортостан, М.Р-Н Уфимский, С.П. Кирилловский Сельсовет, Д Дорогино, ул Новая, д. 30
                      </Box>
                  </Box>
                  <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      borderBottom: '1px solid #EDEEF5',
                      p: '8px 0px',
                      gap: 1,
                  }}>
                      <Box sx={{
                          width: '100%',
                          fontFamily: 'Montserrat',
                          fontWeight: '500',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          Почтовый адрес
                      </Box>
                      <Box sx={{
                          width: '100%',
                          fontFamily: 'Montserrat',
                          fontWeight: '400',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          450502, Республика Башкортостан, М.Р-Н Уфимский, С.П. Кирилловский Сельсовет, Д Дорогино, ул Новая, д. 30
                      </Box>
                  </Box>
                  <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      borderBottom: '1px solid #EDEEF5',
                      p: '8px 0px',
                      gap: 1,
                  }}>
                      <Box sx={{
                          width: '100%',
                          fontFamily: 'Montserrat',
                          fontWeight: '500',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          Фактический адрес
                      </Box>
                      <Box sx={{
                          width: '100%',
                          fontFamily: 'Montserrat',
                          fontWeight: '400',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          450027, Республика Башкортостан, город Уфа,<br /> ул. Уфимское шоссе 36, офис 20
                      </Box>
                  </Box>
                  <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      borderBottom: '1px solid #EDEEF5',
                      p: '8px 0px',
                      gap: 1,
                  }}>
                      <Box sx={{
                          width: '100%',
                          fontFamily: 'Montserrat',
                          fontWeight: '500',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          Директор
                      </Box>
                      <Box sx={{
                          width: '100%',
                          fontFamily: 'Montserrat',
                          fontWeight: '400',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          Мингазова Гузель Фанисовна
                      </Box>
                  </Box>
                  <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      borderBottom: '1px solid #EDEEF5',
                      p: '8px 0px',
                      gap: 1,
                  }}>
                      <Box sx={{
                          width: '100%',
                          fontFamily: 'Montserrat',
                          fontWeight: '500',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          На основании
                      </Box>
                      <Box sx={{
                          width: '100%',
                          fontFamily: 'Montserrat',
                          fontWeight: '400',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          Устава
                      </Box>
                  </Box>
                  <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      borderBottom: '1px solid #EDEEF5',
                      p: '8px 0px',
                      gap: 1,
                  }}>
                      <Box sx={{
                          width: '100%',
                          fontFamily: 'Montserrat',
                          fontWeight: '500',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          ИНН/КПП
                      </Box>
                      <Box sx={{
                          width: '100%',
                          fontFamily: 'Montserrat',
                          fontWeight: '400',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          0245970567 / 024501001
                      </Box>
                  </Box>
                  <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      borderBottom: '1px solid #EDEEF5',
                      p: '8px 0px',
                      gap: 1,
                  }}>
                      <Box sx={{
                          width: '100%',
                          fontFamily: 'Montserrat',
                          fontWeight: '500',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          ОГРН
                      </Box>
                      <Box sx={{
                          width: '100%',
                          fontFamily: 'Montserrat',
                          fontWeight: '400',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          1230200011800
                      </Box>
                  </Box>
                  <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      borderBottom: '1px solid #EDEEF5',
                      p: '8px 0px',
                      gap: 1,
                  }}>
                      <Box sx={{
                          width: '100%',
                          fontFamily: 'Montserrat',
                          fontWeight: '500',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          Расчетный счет
                      </Box>
                      <Box sx={{
                          width: '100%',
                          fontFamily: 'Montserrat',
                          fontWeight: '400',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          40702810016069002547
                      </Box>
                  </Box>
                  <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      borderBottom: '1px solid #EDEEF5',
                      p: '8px 0px',
                      gap: 1,
                  }}>
                      <Box sx={{
                          width: '100%',
                          fontFamily: 'Montserrat',
                          fontWeight: '500',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          Наименование банка
                      </Box>
                      <Box sx={{
                          width: '100%',
                          fontFamily: 'Montserrat',
                          fontWeight: '400',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          Филиал «Центральный» Банка ВТБ (ПАО) в г.Москве
                      </Box>
                  </Box>
                  <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      borderBottom: '1px solid #EDEEF5',
                      p: '8px 0px',
                      gap: 1,
                  }}>
                      <Box sx={{
                          width: '100%',
                          fontFamily: 'Montserrat',
                          fontWeight: '500',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          БИК
                      </Box>
                      <Box sx={{
                          width: '100%',
                          fontFamily: 'Montserrat',
                          fontWeight: '400',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          044525411
                      </Box>
                  </Box>
                  <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      borderBottom: '1px solid #EDEEF5',
                      p: '8px 0px',
                      gap: 1,
                  }}>
                      <Box sx={{
                          width: '100%',
                          fontFamily: 'Montserrat',
                          fontWeight: '500',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          Корреспондентский счет
                      </Box>
                      <Box sx={{
                          width: '100%',
                          fontFamily: 'Montserrat',
                          fontWeight: '400',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          30101810145250000411
                      </Box>
                  </Box>
                  <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      borderBottom: '1px solid #EDEEF5',
                      p: '8px 0px',
                      gap: 1,
                  }}>
                      <Box sx={{
                          width: '100%',
                          fontFamily: 'Montserrat',
                          fontWeight: '500',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          Телефон контактный
                      </Box>
                      <Box sx={{
                          width: '100%',
                          fontFamily: 'Montserrat',
                          fontWeight: '400',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          +7 (937)326-32-95 / +7(917)748-62-42
                      </Box>
                  </Box>
                  <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      borderBottom: '1px solid #EDEEF5',
                      p: '8px 0px',
                      gap: 1,
                  }}>
                      <Box sx={{
                          width: '100%',
                          fontFamily: 'Montserrat',
                          fontWeight: '500',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          Электронная почта
                      </Box>
                      <Box sx={{
                          width: '100%',
                          fontFamily: 'Montserrat',
                          fontWeight: '400',
                          fontSize: { xs: '14px', md: '16px', },
                          lineHeight: { xs: '16px', md: '20px', },
                      }}>
                          office@tkars-service.ru
                      </Box>
                  </Box>
              </Box>
          </Container>

          <Container maxWidth sx={{
              p: { xs: 2, sm: 3, lg: '48px 120px', },
              bgcolor: '#EDEEF5',
          }}>
              <Typography
                  id="Contacts"
                  sx={{
                      fontFamily: 'Montserrat',
                      fontWeight: '700',
                      fontSize: { xs: '20px', md: '36px', },
                      lineHeight: { xs: '24px', md: '44px', },
                      alignSelf: 'stretch',
                      mb: { xs: 2, sm: 3, lg: 6, },
                  }} >
                  Контакты
              </Typography>

              <Box sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  // alignItems: 'flex-start',
                  // alignContent: 'flex-start',
                  // alignSelf: 'stretch',
                  justifyContent: 'space-between',
                  gap: { xs: 2, sm: 3, md: 2, },
              }}>
                  <Box sx={{
                      display: 'flex',
                      gap: 3,
                      minWidth: '288px',
                  }}>
                      <Contact2 />
                      <Box>
                          <Typography
                              sx={{
                                  fontFamily: 'Montserrat',
                                  fontWeight: '700',
                                  fontSize: '16px',
                                  lineHeight: '16px',
                                  mb: 2,
                              }} >
                              Email
                          </Typography>
                          <Link
                              href="mailto:office@tkars-service.ru"
                              underline="none"
                              color="inherit"
                              sx={{
                                  fontFamily: 'Montserrat',
                                  fontWeight: '500',
                                  fontSize: '16px',
                                  lineHeight: '16px',
                              }} >
                              office@tkars-service.ru
                          </Link>
                      </Box>
                  </Box>
                  <Box sx={{
                      display: 'flex',
                      gap: 3,
                      minWidth: '288px',
                  }}>
                      <Contact3 />
                      <Box>
                          <Typography
                              sx={{
                                  fontFamily: 'Montserrat',
                                  fontWeight: '700',
                                  fontSize: '16px',
                                  lineHeight: '16px',
                                  mb: 2,
                              }} >
                              Как связаться?
                          </Typography>
                          <Link
                              href="tel:+7(937)3263295"
                              underline="none"
                              color="inherit"
                              sx={{
                                  fontFamily: 'Montserrat',
                                  fontWeight: '500',
                                  fontSize: '16px',
                                  lineHeight: '16px',
                              }} >
                              +7 (937) 326-32-95
                          </Link>
                          <Box sx={{height: '8px'}}></Box>
                          <Link
                              href="tel:+7(929)7555408"
                              underline="none"
                              color="inherit"
                              sx={{
                                  fontFamily: 'Montserrat',
                                  fontWeight: '500',
                                  fontSize: '16px',
                                  lineHeight: '16px',
                              }} >
                              +7 (929) 755-54-08
                          </Link>
                      </Box>
                  </Box>
                  <Box sx={{
                      display: 'flex',
                      gap: 3,
                      minWidth: '288px',
                  }}>
                      <Contact4 />
                      <Box>
                          <Typography
                              sx={{
                                  fontFamily: 'Montserrat',
                                  fontWeight: '700',
                                  fontSize: '16px',
                                  lineHeight: '16px',
                                  mb: 2,
                              }} >
                              Режим работы
                          </Typography>
                          <Typography
                              sx={{
                                  fontFamily: 'Montserrat',
                                  fontWeight: '500',
                                  fontSize: '16px',
                                  lineHeight: '16px',
                              }} >
                              Пн. – Пт.: с 9:00 до 18:00
                          </Typography>
                      </Box>
                  </Box>
                  <Box sx={{
                      display: 'flex',
                      gap: 3,
                      width: '288px',
                  }}>
                      <Contact1 />
                      <Box>
                          <Typography
                              sx={{
                                  fontFamily: 'Montserrat',
                                  fontWeight: '700',
                                  fontSize: '16px',
                                  lineHeight: '16px',
                                  mb: 2,
                              }} >
                              Как добраться?
                          </Typography>
                          <Typography
                              sx={{
                                  fontFamily: 'Montserrat',
                                  fontWeight: '500',
                                  fontSize: '16px',
                                  lineHeight: '16px',
                                  mb: 2,
                              }} >
                              г. Уфа, ул. Уфимское<br /> шоссе 36, офис 20
                          </Typography>
                      </Box>
                  </Box>
              </Box>
          </Container>
          <iframe
              src="https://yandex.com/map-widget/v1/?um=constructor%3A69ab1e9e3d16c56dd40a37686afc18a0cbe938a6b515025fced776de4daa2d90&amp;source=constructor"
              width="100%"
              height="400"
              frameBorder="0"
          >
          </iframe>

          {/*<iframe*/}
          {/*    src="https://yandex.ru/map-widget/v1/?um=constructor%3Aaa61d0e1591d265b6bf4671f236626cae3826a1a99a1786c9a9fddc7f8814808&amp;source=constructor"*/}
          {/*    width="100%"*/}
          {/*    height="400"*/}
          {/*    frameBorder="0"*/}
          {/*>*/}
          {/*</iframe>*/}

          <Container maxWidth sx={{
              display: 'flex',
              p: { xs: 2, sm: 3, lg: '24px 120px', },
              bgcolor: '#EDEEF5',
              gap: '4px',
              alignItems: 'center',
          }}>
              <Copyright />
              <Link href="/" underline="none" color="inherit">
                  <Typography
                      sx={{
                          fontFamily: 'Montserrat',
                          fontWeight: '400',
                          fontSize: '16px',
                          lineHeight: '20px',
                      }} >
                      2023 ТК АРС-СЕРВИС
                  </Typography>
              </Link>
          </Container>

          <div className="callback-bt">
              <Link className="text-call" href="https://wa.me/79373263295" target="_blank"></Link>
          </div>
      </div>
  )
}